/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';

export default function RechtlicheAbwicklung({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Die rechtliche Abwicklung beim Hausverkauf"
      description="Bei Angebotsannahme übernimmt WirkaufendeinHaus.at die gesamte Treuhandabwicklung über Notar/Rechtsanwalt für Sie. Unser Team kümmert sich vertraulich und diskret um sämtliche Formalitäten, Verträge und Behördengänge."
      image={data.hero.childImageSharp.fluid}
      keywords={['Rechtliche', 'Abwicklung']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Die rechtliche Abwicklung"
        subtitle="beim Ankauf Ihres Hauses"
        image={data.hero}
      />
      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>Lehnen Sie sich zurück!</Heading>
          <Subheading>
            WirkaufendeinHaus.at übernimmt für Sie behördliche Wege und die
            vertragliche Abwicklung
          </Subheading>
        </div>
        <p>
          Werden Sie mit einem Hauskäufer handelseins, muss der Kauf natürlich
          rechtsgültig besiegelt werden. Damit der Käufer auch sicher zum
          Kaufvertragstermin beim Notar erscheint, empfiehlt es sich vorab,
          einen Vorvertrag bzw. ein Kaufangebot zu verfassen. Der Kaufvertrag
          stellt den letzten Schritt auf Ihrem Weg zum Hausverkauf dar.
        </p>

        <p>
          <strong>ACHTUNG:</strong> Der Vorvertrag oder das Kaufangebot muss
          bereits alle Vereinbarungen enthalten und ist für beide Seiten
          einklagbar – es ist daher erforderlich, dass dieses so genau wie
          möglich ausformuliert ist.
        </p>
        <p>
          Nur wenn alle Vereinbarungen schriftlich festgehalten sind, ist
          garantiert, dass der Hausverkauf rechtlich einwandfrei über die Bühne
          geht. Dennoch kann dieser Vorgang mitunter sehr zeit- und
          nervenaufreibend sein.
        </p>
        <p>
          Von der Selbstverfassung eines Kaufvertrages Ihrer Immobilie raten wir
          dringend ab. Nur ein ordentlicher Kaufvertrag mit einem Notar oder
          Rechtsanwalt als Treuhänder sichert Sie in finanzieller und
          rechtlicher Hinsicht ab.
        </p>
        <p>
          Nur mit einer immobilienrechtlich richtigen Ausformulierung stellen
          Sie als Verkäufer sicher, dass alle in finanzieller und rechtlicher
          Hinsicht wichtigen Punkte enthalten sind und es später nicht zum
          Streit kommt.
        </p>
        <p>
          Als Treuhänder stellt der Notar oder Rechtsanwalt auch sicher, dass
          Sie zu Ihrem Geld kommen. Er wird dem Käufer das Eigentumsrecht erst
          dann ins Grundbuch eintragen, wenn die Zahlung des Kaufpreises an Sie
          sichergestellt ist.
        </p>
        <h3>Die gute Nachricht: Bei uns können Sie sich zurücklehnen!</h3>
        <p>
          Unser Angebot enthält alle getroffenen Vereinbarungen in leicht zu
          lesender und übersichtlicher Form und ist sowohl für Sie, als auch für
          uns verbindlich. Es garantiert Ihnen bereits den sicheren Verkauf. Ein
          unabhängiger Notar oder Rechtsanwalt übernimmt die Treuhandschaft und
          haftet Ihnen für die Auszahlung des Kaufpreises wie im Angebot
          vereinbart.
        </p>
        <p>
          Des Weiteren übernimmt WirkaufendeinHaus.at für Sie die gesamte
          Treuhandabwicklung über den Notar/Rechtsanwalt und trägt die Kosten
          für den Kaufvertrag, für die Abwicklung wie auch für die
          Grunderwerbssteuer und die Eintragung ins Grundbuch.
        </p>
        <p>
          Unser Team kümmert sich dabei vertraulich und diskret um sämtliche
          Formalitäten, Verträge und Behördengänge.
        </p>
        <p>
          WirkaufendeinHaus.at übernimmt die Kosten für den Kaufvertrag, für die
          Abwicklung wie auch für die Grunderwerbssteuer und die Eintragung ins
          Grundbuch.
        </p>
        <p>
          Sie tragen lediglich mögliche Kosten wie zum Beispiel Kosten für
          Löschungen von Grundbucheintragungen wie Kredite oder Exekutionen
          (dies wird üblicherweise von Ihrer Hausbank verlangt) sowie eine
          allfällige Immobilienertragssteuer. Gerne stellen wir Ihnen dazu einen
          Steuerberater zur kostenlosen Beratung zur Verfügung.
        </p>
        <p>Schneller, sicherer und bequemer geht’s nicht!</p>
        <p>
          <strong>TIPP:</strong> Handelt es sich bei der Immobilie um Ihren
          tatsächlichen Hauptwohnsitz der letzten 5 Jahre (oder: 2 Jahre
          durchgehender Hauptwohnsitz seit Kauf oder Neubau), entfällt die
          Immobilienertragssteuer.*
        </p>
        <p sx={{ fontStyle: 'italic' }}>
          * Bitte beachten Sie, dass nur Anwälte, Notare und Steuerberater über
          die Immobilienertragssteuer beraten dürfen und es sich hierbei
          lediglich um einen Tipp ohne Haftung handelt.
        </p>
      </Section>
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-rechtliche-abwicklung" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
